import React from "react"
//import { Link } from "gatsby"

//import Layout from "../components/layout"
//import Image from "../components/image"
//import SEO from "../components/seo"
//import  load from 'load-script';
//import '../assets/css/main.css';
//import '../assets/css/noscript.css';

export default class IndexPage extends React.Component {
//const IndexPage = () => (



render(){
  return (

    <>


      </>

)}}
